import { useState, useEffect } from 'react';
import Loading from '../Elements/Loading';
import LocalStorage from 'Helpers/MFXLocalStorage';
import loadJsonData from '../Utils/loadJsonData';
import Pagination from '../Elements/Pagination';
import { useSearchParams } from 'react-router-dom';
import ModalOverlay from '../Common/ModalOverlay';
import Table from '../Elements/Table';
import Addbutton from '../Elements/Addbutton';
import { AddViewProvider } from './AddView';
import ModalPopup from '../Common/ModalPopup';
import FullTextSearch from '../Elements/FullTextSearch';
import Filter from '../Elements/Filter';
import Filterbutton from '../Elements/Filterbutton';
import SessionStorage from '../../../Helpers/MFXSessionStorage';

import { getPropertiesPerType, getContext } from '../../../Helpers/getPropertiesPerType';
/**
 * Renders a Table / List of all Tasks.
 * @returns {JSX.Element} Rendered component
 */

export function TasksView(props: any): JSX.Element {
    const [tableStates, setTableStates] = useState({
        sort: true,
        curData: [],
        dataLoaded: false,
        disableMode: true,
        addMode: false,
        detailData: {},
        detailView: false,
    });
    //#region Url Parameter
    const [urlsLoaded, setUrlsLoaded] = useState(false);

    const pageName = 'page_' + props.ViewName + '_' + props.LayoutName;
    const pageSizeName = 'pageSize_' + props.ViewName + '_' + props.LayoutName;
    const filterName = 'filter_' + props.ViewName + '_' + props.LayoutName;
    const specialFilterName = 'specialFilter_' + props.ViewName + '_' + props.LayoutName;
    const searchName = 'searchText_' + props.ViewName + '_' + props.LayoutName;

    var [pageParam, setPageParam] = useState((useSearchParams()[0].get(pageName) as any) ?? 1);
    const [searchTextParam, setSearchTextParam] = useState(useSearchParams()[0].get(searchName) ?? '');
    const [pageSizeParam, setPageSizeParam] = useState(useSearchParams()[0].get(pageSizeName));
    const [filterParam, setFilterParam] = useState(useSearchParams()[0].get(filterName));
    const [urlParamsObject, setUrlParamsObject] = useState(null as any);
    const [processIndex, setProcessIndex] = useState(useSearchParams()[0].get('process'));
    //#endregion Url Parameters
    const curProperties = getPropertiesPerType(props.ViewName);
    const [specialFilterFromUrl, setSpecialFilterFromUrl] = useState(useSearchParams()[0].get(specialFilterName)?.replace(' AND ', '&'));
    const [specialFilter, setSpecialFilter] = useState(
        SessionStorage.getData(LocalStorage?.getTabData()?.primaryType + '_' + specialFilterName)
            ? SessionStorage.getData(LocalStorage?.getTabData()?.primaryType + '_' + specialFilterName)
            : specialFilterFromUrl
            ? specialFilterFromUrl
            : props.designer?.Settings?.ExtraFilter
            ? props.designer?.Settings?.ExtraFilter[0] === '&'
                ? props.designer?.Settings?.ExtraFilter.substring(1)
                : props.designer?.Settings?.ExtraFilter
            : curProperties?.extraFilter
            ? curProperties?.extraFilter
            : ''
    );
    const [tableData, setTableData] = useState(null as any);
    const [defaultFiltersLoaded, setDefaultFiltersLoaded] = useState(false);
    const [filterActive, setFilterActive] = useState(props.designer?.Filter && props.designer?.Filter?.Data.length > 0 && !props.designer.Settings?.IsListView && !props.designer?.Settings?.IsFilterClosed ? true : false);
    const changeFilterMode = () => {
        setFilterActive(prev => !prev);
    };

    useEffect(() => {
        if (urlsLoaded) {
            var paramString = '';
            Object.entries(urlParamsObject).forEach((obj: any) => {
                if (!obj[1]) return;
                if (paramString) paramString += '&';

                paramString += obj[0] + '=' + obj[1].toString().replaceAll('&', ' AND ');
            });
            var comb = combineUrlParams(paramString, window.location.href.split('?')[1]);
            var newurl = window.location.href.split('?')[0] + (comb ? '?' + comb : '');
            if (window.location.href !== newurl) window.history.replaceState({ path: newurl }, '', encodeURI(newurl));
        }
    }, [urlParamsObject]);

    function combineUrlParams(param1, param2) {
        if (!param2) return param1;
        var param1A;
        var param2A;

        try {
            param1A = decodeURIComponent(param1)?.split('&');
        } catch (ex) {
            param1A = param1?.split('&');
        }
        try {
            param2A = decodeURIComponent(param2)?.split('&');
        } catch (ex) {
            param2A = param2?.split('&');
        }
        param2A?.forEach(p => {
            var name = p.split('=')[0];
            var exists = param1A?.find(p1 => p1.split('=')[0] === name);
            if (!exists && name !== filterName) {
                param1 += (param1 ? '&' : '') + p;
            }
        });
        return param1;
    }

    useEffect(() => {
        if (defaultFiltersLoaded) {
            var res = filterParam as any;
            if (res) {
                props?.designer?.Filter?.Data?.filter((f: any) => f.ReadOnly)?.forEach(d => {
                    var index = res.indexOf(d.Key.replace('-', '.') + ' ');
                    if (index === -1) {
                        window.location.href = window.location.href.split('?')[0];
                    } else {
                        var start = res.indexOf('"', index) + 1;
                        var end = res.indexOf('"', start);
                        if (d.DefaultValue && typeof d.DefaultValue === 'string' && d.DefaultValue?.toLowerCase().split('.')[0] === 'userdata') {
                            console.log(d.DefaultValue);
                            console.log(LocalStorage?.getData('UserData'));
                            d.DefaultValue = LocalStorage?.getData('UserData') ? LocalStorage?.getData('UserData')[d.DefaultValue.split('.')[1]] : d.DefaultValue;
                            if (isNaN(d.DefaultValue)) d.DefaultValue = 999;
                        }
                        var sub = res.substring(0, start) + (d.Operator === 'LIKE' ? '%' + d.DefaultValue + '%' : d.DefaultValue) + res.substring(end);
                        res = sub;
                        index = res.indexOf(d.Key + ' ');
                    }
                });
                setUrlParams(filterName, res);
                if (specialFilter) {
                    setUrlParams(specialFilterName, specialFilter);
                }
            }

            setUrlsLoaded(true);
        }
    }, [defaultFiltersLoaded]);

    function setUrlParams(type: any, query: any) {
        switch (type) {
            case pageName:
                setPageParam(query);
                break;
            case pageSizeName:
                setPageSizeParam(query);
                break;
            case filterName:
                setFilterParam(query);
                SessionStorage.createSession(LocalStorage?.getTabData()?.primaryType + '_' + type, query);
                break;
            case specialFilterName:
                SessionStorage.createSession(LocalStorage?.getTabData()?.primaryType + '_' + type, query);
                break;
            case searchName:
                setSearchTextParam(query);
                break;
        }
        setUrlParamsObject(prev => {
            return { ...prev, [type]: query };
        });
    }

    //#region  Pagination

    const [activePage, setActivePage] = useState(pageParam++);
    const [totalPages, setTotalPages] = useState(1);
    const [sortingColumn, setSortingColumn] = useState(
        Object.entries((props.designer.TableElements ?? props.designer.Tablehead)[0]).find((d: any) => d[1].DefaultSortingColumn)
            ? (Object.entries((props.designer.TableElements ?? props.designer.Tablehead)[0]).find((d: any) => d[1].DefaultSortingColumn) as any)[1].Key.replace('-', '.') +
                  ((Object.entries((props.designer.TableElements ?? props.designer.Tablehead)[0]).find((d: any) => d[1].DefaultSortingColumn) as any)[1].SortDescending ? ' desc' : '')
            : props.ViewName === 'OfferView'
            ? 'Number'
            : props.ViewName === 'SystemInfoView'
            ? 'Name1'
            : 'Index'
    );
    const [pageSize, setPageSize] = useState(pageSizeParam ? pageSizeParam : props?.designer?.Settings?.NumberOfEntries ? props?.designer?.Settings?.NumberOfEntries : 20);
    const [totalEntries, setTotalEntries] = useState(0);
    const activeHandler = (clickedActive: any) => {
        setUrlParams(pageName, clickedActive);
        setActivePage(parseInt(clickedActive));
    };

    //#endregion  Pagination

    useEffect(() => {
        if (defaultFiltersLoaded) {
            loadData();
        }
    }, [activePage, sortingColumn, defaultFiltersLoaded]);

    useEffect(() => {
        if (defaultFiltersLoaded) {
            setActivePage(1);
            loadData();
        }
    }, [searchTextParam, pageSize, filterParam, specialFilter]);

    function loadData() {
        setTableStates({ ...tableStates, dataLoaded: false });
        var filterentries = '';
        var specialFilterEntries = specialFilter ?? '';
        // ---- IF BACKEND IS READY
        let currentData = LocalStorage?.getTabData();
        if (currentData?.primaryType === 'customer' && curProperties?.customerFilter) {
            filterentries += curProperties?.customerFilter + '=' + currentData.primaryId;
        } else if (currentData?.primaryType === 'customer' && curProperties?.specialCustomerFilter) {
            specialFilterEntries += (specialFilter ? '&' : '') + curProperties?.specialCustomerFilter + '=' + currentData.primaryId;
        }
        let allFilterEntries = '';
        allFilterEntries += filterParam ? (allFilterEntries ? ' AND ' + filterParam : filterParam) : '';
        allFilterEntries += filterentries ? (allFilterEntries ? ' AND ' + filterentries : filterentries) : '';
        allFilterEntries += processIndex ? (allFilterEntries ? ' AND Process.Index=' + processIndex : 'Process.Index=' + processIndex) : '';

        loadJsonData(
            '/' +
                LocalStorage?.getConnectionKey() +
                curProperties?.url +
                '?Paging.Page=' +
                activePage +
                '&Paging.PageSize=' +
                pageSize +
                (searchTextParam ? '&Filter.FilterText=' + searchTextParam : '') +
                (allFilterEntries ? '&FilterEntries=' + allFilterEntries : '') +
                (specialFilterEntries ? '&' + specialFilterEntries : '') +
                (sortingColumn ? '&Paging.SortingColumn=' + sortingColumn : '')
        ).then(data => {
            if (data.Meta.Success && data.ListMeta.EntriesCount === 0 && activePage !== 1) {
                activeHandler(1);
            } else if (data.Meta.Success && data.Data) {
                // dispatch((curAction() as any).action.init(data));
                if (props.ViewName === 'SystemInfoView') {
                    setTableData(data.Data[0].InvoiceSytemInfos);
                } else {
                    setTableData(data.Data);
                }
                setTotalPages(data.ListMeta.PagesCount);
                setTotalEntries(data.ListMeta.EntriesCount);
                setTableStates({ ...tableStates, dataLoaded: true, curData: (data as any).Data as any });
            } else if (data.Meta.Success) {
                setTotalPages(data.ListMeta.PagesCount);
                setTotalEntries(data.ListMeta.EntriesCount);
                setTableStates({ ...tableStates, dataLoaded: true, curData: [] });
            } else {
                setTotalPages(0);
                setTotalEntries(0);
                setTableStates({ ...tableStates, dataLoaded: true, curData: [] });
            }
        });
    }

    const addNewTab = (data: any, idx: number) => {
        if (!LocalStorage.hasKeyInDB(curProperties?.type.toUpperCase() + '_' + idx)) {
            LocalStorage.createTab(
                {
                    primaryId: idx,
                    primaryType: curProperties?.type as any,
                    context: getContext(data, props.ViewName),
                },
                true,
                true,
                '/processes/' + curProperties?.type,
                '/processes/' + curProperties?.type
            );
        } else {
            LocalStorage.setCurrent(curProperties?.type.toUpperCase() + '_' + idx, true, '/processes/' + curProperties?.type);
        }
    };
    function setSpecializedFilter(newFilter) {
        setSpecialFilter(prev => {
            var res = newFilter;
            prev?.split('&')
                ?.filter(x => x?.split('=')[0] !== newFilter?.split('=')[0])
                ?.forEach(f => {
                    if (f) {
                        res += '&' + f;
                    }
                });
            setUrlParams(specialFilterName, res);
            return res;
        });
    }
    return (
        <>
            <div className="mfx-view">
                <b></b>
                {!tableStates.detailView && !props.designer?.Settings?.IsListView && (
                    <div className="mfx-action-buttons">
                        <FullTextSearch value={searchTextParam} loadSearchData={val => setUrlParams(searchName, val)}></FullTextSearch>
                        {props.designer?.Filter?.Data?.length > 0 && <Filterbutton setFilterMode={changeFilterMode} filterMode={filterActive} />}

                        {curProperties?.actions.add && (
                            <Addbutton
                                disableChangeHandler={(save: boolean) => {
                                    setTableStates({ ...tableStates, addMode: !tableStates.addMode });
                                }}
                                disableMode={tableStates.addMode}
                            />
                        )}
                    </div>
                )}
                <Filter specialFilter={specialFilter} setSpecialFilter={setSpecializedFilter} filterQuery={filterParam} setUrlParams={setUrlParams} filterName={filterName} setDefaultFiltersLoaded={setDefaultFiltersLoaded} filters={props.designer.Filter} filterActive={filterActive} />

                {!tableStates.detailView && props.designer && (
                    <>
                        {!props.designer?.Settings?.IsListView && (
                            <Pagination
                                active={activePage}
                                size={totalPages}
                                entries={totalEntries}
                                pageSize={pageSize}
                                step={2}
                                index={1}
                                onClickHandler={activeHandler}
                                onPageSizeChangeHandler={(size: number) => {
                                    setPageSize(size);
                                    setUrlParams(pageSizeName, size);
                                }}
                            />
                        )}

                        {tableStates.dataLoaded && (
                            <Table
                                sortingColumn={sortingColumn}
                                sortTable={e => setSortingColumn(e)}
                                data={tableData}
                                states={tableStates}
                                addNewTab={addNewTab}
                                type={curProperties?.type.toUpperCase()}
                                designer={props.designer}
                                detailType={curProperties?.detailView ? curProperties?.type : ''}
                                withOpenTab={curProperties?.actions.tab}
                                totalPages={totalPages}
                                pageSize={pageSize}
                                totalEntries={totalEntries}
                            />
                        )}
                        {!tableStates.dataLoaded && (
                            <>
                                <ModalOverlay loading="loading">
                                    <Loading />
                                </ModalOverlay>
                            </>
                        )}
                        {!props?.designer?.Settings?.IsListView && (
                            <Pagination
                                className="mfx-paging-bottom"
                                active={activePage}
                                size={totalPages}
                                step={2}
                                pageSize={pageSize}
                                entries={totalEntries}
                                index={2}
                                onClickHandler={activeHandler}
                                onPageSizeChangeHandler={(size: number) => {
                                    setPageSize(size);
                                    setUrlParams(pageSizeName, size);
                                }}
                            />
                        )}
                    </>
                )}
                {tableStates.addMode && (
                    <ModalPopup modalClass="mfx-add-modal">
                        <AddViewProvider changeView={() => setTableStates({ ...tableStates, addMode: false })} viewName={curProperties?.addView} fixCustomer={LocalStorage.getCurrentPath().indexOf('CUSTOMER') > 0 ? true : false} viewSettings={curProperties}>
                            <div className="mfx-action-buttons">
                                <Addbutton
                                    disableChangeHandler={() => {
                                        setTableStates({ ...tableStates, addMode: !tableStates.addMode });
                                    }}
                                    disableMode={tableStates.addMode}
                                    save={false}
                                />
                            </div>
                        </AddViewProvider>
                    </ModalPopup>
                )}
            </div>
        </>
    );
}

export default TasksView;
