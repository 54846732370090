/**
 * Renders a Line Chart
 * @returns {JSX.Element} The Pie Chart
 */

import { useEffect, useState } from 'react';
import { Chart } from 'react-google-charts';

const LineChart = props => {
    const [chartData, setChartData] = useState({
        data: null,
        tickValues: [],
        indexBy: null,
        keys: [],
        labels: [],
    });
    useEffect(() => {
        if(props?.chart?.Data?.length <= 0) return;
        let lineData = [];
        let type = 'number';
        if (new Date(props.chart.Data[0].id) !== 'Invalid Date' && isNaN(props.chart.Data[0].id)) {
            type = 'date';
        }

        lineData[0] = [{ type: type }, props.chart.Data[0].label ?? props.chart.Data[0].label, props.chart.Data[0].label2];

        props.chart.Data.forEach((d, inx) => {
            //lineData[inx + 1] = [type === 'date' ? parseDMY(d.id) : d.id,(isNaN(parseInt(d.value))? 0 : parseInt(d.value)),(isNaN(parseInt(d.value2))? 0 : parseInt(d.value2))];
            lineData[inx + 1] = [type === 'date' ? parseDMY(d.id) : d.id,+d.value.replace(',','.') ?? 0,+d.value2.replace(',','.') ?? 0];

        });

        setChartData({ ...chartData, data: lineData });
    }, [props]);
    const parseDMY = s => {
        let [d, m, y] = s.split(/\D/);
        return new Date(y, m - 1, d);
    };
    const data = [
        ['Year', 'Sales', 'Expenses'],
        ['2004', 1000, 400],
        ['2005', 1170, 460],
        ['2006', 660, 1120],
        ['2007', 1030, 540],
      ];
    return (
        <>
            {chartData.data?.length > 0 && (
                <>
                    <div className='mfx-chart line-chart'>
                        <Chart chartType='Line' width='100%' height='300px' data={chartData.data} options={{ colors: ['#eede34', '#5BE12C'] }} />
                    </div>
                </>
            )}
            {chartData.data?.length === 0 && (
                <h4 className='nothing-found'>
                    <div className='success-false extra-small'>
                        <div className='icon-style-arrow'>
                            <i className='icon-close'></i>
                        </div>
                    </div>
                    {window.Dictionary.getTranslation(5484)}
                </h4>
            )}
        </>
    );
};
export default LineChart;
