import { IStatedSession } from 'Components/Lib/Hooks/useSessions';
import LocalStorage, { ILocal } from 'Helpers/MFXLocalStorage';
import { useEffect, useState } from 'react';

/**
 * Renders a Tab of the right Type
 * with different Properties
 * @returns {JSX.Element} Rendered component
 */

export default function TabEntry(props: IStatedSession) {
    let content = <strong>{window.Dictionary ? window.Dictionary.getTranslation(2966) : 'Start'}</strong>;
    const [submenuOpen, setSubmenuOpen] = useState(false);
    let tabs = LocalStorage.getAllTabKeys();

    switch (props.data.primaryType) {
        case 'customer':
            content = <TabCustomer {...props} />;
            break;
        /*case 'offer':
      content = <TabOffer {...props} />;
      break;
    */
    }

    return (
        <li className={props.selected ? 'selected' : ''}>
            <button onClick={() => LocalStorage.setCurrent(props.id, true)}>{content}</button>

            {
                /* Start X remove all Tabs except the Start-Tab */
                props.id.split('_').at(-1)  !== 'START' && (
                    <button className="no-styling close-tab" onClick={() => LocalStorage.removeTab(props.id)}>
                        <i className="icon-close"></i>
                    </button>
                )
            }

            {
                /* Start Tab (not mobile Version)
                 * Start X remove all Tabs except the Start-Tab
                 */
                props.selected && window.innerWidth > window.mobileBreakPoint && props.id.split('_')[2] === 'START' && (
                    <>
                        <button className="no-styling close-tab" onClick={() => LocalStorage.removeAllTabs()}>
                            <i className="icon-close"></i>
                        </button>
                    </>
                )
            }
            {
                /* Start Tab mobile Version
                 * Arrow to open Menu
                 */
                props.selected && window.innerWidth <= window.mobileBreakPoint && (tabs as any).length > 1 && (
                    <>
                        {props.id.split('_').at(-1)  === 'START' && (
                            <button className="no-styling close-tab" onClick={() => LocalStorage.removeAllTabs()}>
                                <i className="icon-close"></i>
                            </button>
                        )}
                        <button className="open-mobile-tab" onClick={props.onClickHandler}>
                            <i className="icon-arrow-down"></i>
                        </button>
                    </>
                )
            }
        </li>
    );
}

/*
 * Renders a Customer-Tab by getting the Data from the LocalStorage
 */

function TabCustomer(props: IStatedSession) {
    let data = LocalStorage.getTabData(props.id) as ILocal;

    return (
        <>
            <i className="icon-customer tabicon" style={{ backgroundColor: 'var(--customerTabIconColor)' }}></i>
            <strong>{data?.context?.customerName}</strong>
        </>
    );
}

/*function TabOffer(props: IStatedSession) {
  let data = LocalStorage.getTabData();

  return(
    <>
      <i className='icon-customer tabicon' style={
          {backgroundColor: window.Colors.OfferBg ? window.Colors.OfferBg : '#000',
           color: window.Colors.OfferColor ? window.Colors.OfferColor : '#fff'
          }}></i> 
      <strong>{data?.primaryId}</strong>
    </>)
    ;
}*/
